.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.workingswrap {
  position: relative;
}
.workingswrap .container-fluid {
  background: #000;
}
.workingswrap .work {
  position: relative;
  height: 0px;
  width: 100%;
  overflow: hidden;
  display: block;
}
.workingswrap .work.halfheight {
  padding-bottom: 69%;
}
.workingswrap .work .bg {
  position: absolute;
  left: 0px;
  height: 100%;
  width: 100%;
  top: 0px;
  opacity: 0.8;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.workingswrap .work .bg img {
  width: 100%;
  /* height: auto; */
  height: 100%;
}
.workingswrap .work .text {
  position: absolute;
  left: 0px;
  height: 100%;
  width: 100%;
  top: 0px;
  padding: 30px;
  /* rgb(6 144 206) */
  /* background: rgb(2,0,36); */
  background: linear-gradient(
    90deg,
    rgba(6, 143, 206, 0) 0%,
    rgba(6, 143, 206, 0.178) 35%,
    rgba(6, 143, 206, 0.425) 100%
  );
}
.workingswrap .work .text h5 {
  font-weight: "bold";
  text-transform: uppercase;
  z-index: 2;
  position: absolute;
  left: 0px;
  height: 100%;
  width: 100%;
  top: 50%;
  margin-top: -26px;
  text-align: center;
  color: rgb(255, 255, 255);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.workingswrap .work .text h5 span {
  display: block;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.workingswrap .work .text:after {
  position: relative;
  width: 100%;
  display: block;
  height: 100%;
  border: 3px solid #fff;
  background: rgba(27, 47, 73, 0.5);
  content: "";
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -ms-transform: scale(0.4);
  -o-transform: scale(0.4);
  transform: scale(0.4);
  opacity: 0;
  visibility: hidden;
}
.workingswrap .work:hover .bg {
  opacity: 0.4;
}
.workingswrap .work:hover .text h5 {
  margin-top: -30px;
}
.workingswrap .work:hover .text h5 span {
  margin-top: 10px;
}
.workingswrap .work:hover .text:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}
.workingswrap .work.fullheight {
  padding-bottom: 138%;
}
.workingswrap .buttonwrap {
  text-align: center;
  margin-top: 29px;
}

#layoutAuthentication {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  min-height: 100vh;
}
#layoutAuthentication #layoutAuthentication_content {
  min-width: 0;
  flex-grow: 1;
}
#layoutAuthentication #layoutAuthentication_footer {
  min-width: 0;
}

.form-control {
  border-radius: 0.5rem !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.buttonClass {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  height: 32px;
}
.logoSize {
  height: 3rem;
}
.card-header {
  background-color: rgb(0 0 0 / 0%) !important;
}
.card-header1 {
  background-color: #0690ce !important;
  padding: 0.75rem 1.25rem;

  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.team .member {
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  background: #fff;
}

.team .member img {
  max-width: 60%;
  border-radius: 50%;
  margin: 0 0 30px 0;
}

.team .member h5 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
}

.team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}

.team .member p {
  padding-top: 10px;
  font-size: 14px;
  font-style: italic;
  color: #aaaaaa;
}

.team .member .social {
  margin-top: 15px;
}

.team .member .social a {
  color: #919191;
  transition: 0.3s;
}

.team .member .social a:hover {
  color: #0690ce;
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}
#logoResize {
  height: 200px;
}
#headingTop {
  margin-top: 3rem;
}
@media only screen and (max-width: 600px) {
  #logoResize {
    height: 50px;
  }
  #headingTop {
    margin-top: 3rem;
  }
}
@media (min-width: 420px) {
  .logoSize {
    height: 4rem;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
